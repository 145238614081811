import { REHYDRATE } from 'redux-persist/lib/constants';
import { add_to_path, new_section_from_title, add_note_to_section  } from '../reducers/sectionsSlice';
import { add_section_to_note } from '../reducers/notesSlice';
import draftToHtml from 'draftjs-to-html';

const addToPath = store => next => action => {
	if(action.type == add_to_path){
		const { getState, dispatch } = store
		const title = action.payload.title
		
		if(title){
			dispatch(new_section_from_title(title))
			
			const { sections } = getState()
			if(!sections.byId[sections.byTitle[title]].notes.some((id) => id === action.payload.id)){
				dispatch(add_note_to_section( {note: action.payload.id, section: sections.byTitle[title]} ))
				dispatch(add_section_to_note( {id: action.payload.id, section: sections.byTitle[title]} ))
			}
		}
	}

	next(action)
}

function getIter(obj, cb){
	let ret = {}
	for(const [key, values] of Object.entries(obj)){
		ret[key] = cb(values)
	}
	return ret
}

function transformEntity(entity, text){
	console.log(entity, text)
	if(entity.type === 'TITLE')
		return `<h1>${text}</h1>`
	return `${text}`
}

const migrate = store => next => action => {
	console.log(REHYDRATE)
	if(action.type === REHYDRATE){
		console.log('rehydrating')
		console.log(action.payload)
		const state = action.payload;
		const states = {
			...state,
			notes: {
				...state.notes,
				byId:{
					...state.notes.byId,
					...getIter(state.notes.byId, values => ({...values, state: draftToHtml(values.state, null, null, transformEntity)}))
				}
			}
		}
		// Object.values(state.notes.byId).map(note => {
		// 	console.log(draftToHtml(note.state, null, null, transformEntity))
		// })
		console.log(states)
	}
	next(action)
}

export default [ addToPath ]