import React, { useContext } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getSectionById } from '../REDUX/selectors';
import { select_section } from '../REDUX/reducers/sectionsSlice';

import { PageContext } from '../CONTEXT/pages-navigation';

const Container = styled(Link)`
	/*border: 1px solid red;*/
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-color: ${({selected}) => selected ? '#2196F3' : 'transparent'};
	margin-bottom: 8px;
	cursor: pointer;
	text-decoration: none;
	color: #333;
	&:hover{
		border-color: #2196F3;
	}
`
const SelectContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const BorderColor = styled.div`
	width: 12px;
	background-color: ${({selected}) => selected ? '#2196F3' : 'transparent'};
`

const Text = styled.span`
	flex-grow: 1;
	padding: 4px 0;
	text-align: center;
`

export function Section({section, select_section}){
	const { id, selected, title} = section
	const { setPage } = useContext(PageContext)
	const path = generatePath("/:section", {section: title})
	const handleClick = () => {
		select_section(id)
		setPage('note')
	}
	return(
		<Container to={path} selected={selected} onClick={handleClick}>
			<SelectContainer selected={selected}>
				<BorderColor selected={selected}/>
				<Text>{title}</Text>
			</SelectContainer>
		</Container>
	)
}

const mapState = (state, props) => ({
	section: getSectionById(state, props)
})

export default connect(mapState, { select_section })(Section)