import { createSelector } from '@reduxjs/toolkit';

const selectSections = state => state.sections.byId
const selectAllSections = state => state.sections.allIds
const selectSelectedSection = state => state.sections.selected

export const getSections = createSelector(
	[selectSections],
	allSections => Object.values(allSections)
)

export const getSectionsId = createSelector(
	[selectAllSections],
	(sections) => sections
)

export const getSectionById = createSelector(
	[selectSections, selectSelectedSection, (state, {id}) => id],
	(sections, selected, id) => {
		return {...sections[id], selected: id === selected}
	}
)

export const getSelectedSection = createSelector(
	[selectSections, selectSelectedSection],
	(sections, section) => {
		return sections[section]
	}
)

export const getNotesBySectionId = createSelector(
	[selectSections, (state, id) => id],
	(sections, id) => {
		return sections[id].notes
	}
)

export const getNoteSections = createSelector(
	[selectSections, (state, ids) => ids],
	(sections, ids) => {
		console.log(ids)
		// return []
		return ids.map(id => ({id, title: sections[id].title}))
	}
)

export const getSelectedNoteId = createSelector(
	[selectSections, selectSelectedSection],
	(sections, section) => sections[section].selectedNote
)