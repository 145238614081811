import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Plus, ChevronLeft } from 'react-feather';
import { useMediaQuery } from 'react-responsive';

import { new_note } from '../REDUX/reducers/notesSlice';
import { getNotesBySectionId, getSelectedNoteId } from '../REDUX/selectors';

import { PageContext } from '../CONTEXT/pages-navigation';
import Note from './note';
import Button from '../components/button';

const Container = styled.div`
	border-right: 1px solid #2196F3;
	border-left: 1px solid #2196F3;
	width: 100%;
	display: flex;
	flex-direction: column;
`
const SectionsContainer = styled.div`
	flex-grow: 1;
`
const SectionIndicator = styled.div`
	height: 48px;
	margin-bottom: 4px;
	display: flex;
	align-items: center;
	background-color: #2196F3;
`
const Title = styled.h2`
	margin: 0;
	color: #FFF;
	margin: 0 auto;
`

const BackButton = styled(Link)`
	background: #2196F3;
  	color: #FFF;
  	font-size: 16px;
  	border: 0;
  	width: 34px;
  	height: 100%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	text-transform: uppercase;
	cursor: pointer;
	&:hover{
		background: #64B6F7;
		outline: 0;
	}
`
const AddButton = styled(BackButton)``
const ButtonContainer = styled.div`
	width: 34px;
	height: 100%;
`

function Sections({notes, selected, title, new_note}){
	const notesArr = notes.map(note => <Note key={note} id={note} selected={note === selected}/>)
	const isMobile = useMediaQuery({maxWidth: 767})

	return (
		<Container>
			<SectionIndicator >
				<ButtonContainer>
					{isMobile && <BackButton to={`/`} ><ChevronLeft/></BackButton>}
				</ButtonContainer>
				<Title>{title}</Title>
				<AddButton onClick={() => new_note()}><Plus size={24}/></AddButton>
			</SectionIndicator>
			<SectionsContainer>
				{notesArr}
			</SectionsContainer>
		</Container>
	)
}

const mapState = (state, {id}) => 
	({ 
		notes: getNotesBySectionId(state, id),
		selected: getSelectedNoteId(state)
	})

export default connect(mapState, { new_note })(Sections) 