import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
	background: ${({colors}) => colors[0]};
  	color: #FFF;
  	font-size: 24px;
  	border: 0;
  	/*height: 34px;*/
  	width: 34px;
  	height: 100%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	text-transform: uppercase;
  	/*border-radius: 4px;*/
	margin-left: 8px;
	cursor: pointer;
	&:hover{
		background: ${({colors}) => colors[1]};
		outline: 0;
	}
`
export default function Button({children, variant='primary', ...rest}){
	const variantMap = {'primary': ['#2196F3', '#64B6F7'], 'secondary': ['#333', '#707070']}
	return(
		<Container {...rest} colors={variantMap[variant]}>
			{children}
		</Container>
	)
}