import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ChevronLeft } from 'react-feather';

import { PageContext, pages } from './CONTEXT/pages-navigation';

import NoteBook from './notebookScreen/notebook';
import Sections from './sectionScreen/sectionScreen';
import NoteEditor from './noteScreen/noteEditorScreen';


const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
`
const MobileContainer = styled.div`
	height: 100%;
	width: 100%;
`
const NoteBookContainer = styled.div`
	position: relative;
	width: 20vw;
	${({isMobile}) => (
		isMobile && css`
		height: 100%;
		width: 100%;
		`
	)}
`
const SectionsContainer = styled.div`
	position: relative;
	width: 20vw;
	display: flex;
	${({isMobile}) => (
		isMobile && css`
		height: 100%;
		width: 100%;
		`
	)}
`
const EditorContainer = styled.div`
	position: relative;
	flex-grow: 1;
	display: flex;
	width: 60vw;
	${({isMobile}) => (
		isMobile && css`
		height: 100%;
		width: 100%;
		`
	)}
`

const BackButton = styled.button`
	position: absolute;
	top: 8px;
	left: 8px;
	width: 48px;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background-color: #2196F3;
	color: #FFF;
`

const Mobile = ({children}) => {
	const isMobile = useMediaQuery({ maxWidth: 767 })
	const [current, setCurrent] = useState(pages[0])
	const context = {isPage: true, page: current, setPage: setCurrent}
	const screens = {'section': NoteBook, 'note': Sections, 'editor': NoteEditor}
	const Screen = screens[current]
	console.log(isMobile)
	return (isMobile &&
		<PageContext.Provider value={context}>
		 <Container>
		 	<MobileContainer>
		 		<Screen/>
		 	</MobileContainer>
		</Container>
		</PageContext.Provider>
	)
}

const Desktop = ({children}) => {
	const isDesktop = useMediaQuery({ minWidth: 768 })
	const isMobile = useMediaQuery({maxWidth: 767})
	const ScreenSizeContainer = isMobile ? Switch : React.Fragment

	return (
		<Container>
			<ScreenSizeContainer>
				<Route exact={isMobile} path="/">
					<NoteBookContainer isMobile={isMobile}>
						<NoteBook/>
					</NoteBookContainer>
				</Route>
				<Route exact={isMobile} path="/:section">
					<SectionsContainer isMobile={isMobile}>
						<Sections/>
					</SectionsContainer>
				</Route>
				<Route exact={isMobile} path="/:section/:note">
					<EditorContainer isMobile={isMobile}>
						<NoteEditor/>
					</EditorContainer>
				</Route>
			</ScreenSizeContainer>
		</Container>
	)
}

export default function Main(){
	
	return (
		<Desktop/>
	)
}