import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSelectedNoteId } from '../REDUX/selectors';

import NoteEditor from './noteEditor';

function NoteEditorScreen({note}){
	const [ id, setId ] = useState(note)
	useEffect(() => {
		if(note)
			setId(note)
	},[note])

	return (
		<NoteEditor key={id} id={id}/>
	)
}

const mapState = state => ({
	note: getSelectedNoteId(state)
})

export default connect(mapState)(NoteEditorScreen)