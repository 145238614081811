import React from 'react';
import { connect } from 'react-redux';

import { getSelectedSection } from '../REDUX/selectors';

import Section from './sections';

function SectionScreen({section}){
	return (
		<Section id={section.id} title={section.title}/>
	)
}

const mapState = (state) => ({
	section: getSelectedSection(state)
})

export default connect(mapState)(SectionScreen)