import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist'

import reducer from './reducers';
import middlewares from './middlewares';

// console.log(middlewares)

export const store = configureStore({
	reducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware({
		serializableCheck: false,
		immutableCheck: false
	}).concat(middlewares)
})

export const persistor = persistStore(store)

