import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { X as Delete, ChevronLeft } from 'react-feather';
import { useMediaQuery } from 'react-responsive';

import { getNoteSections, getNoteSectionsIds } from '../REDUX/selectors';
import { add_to_path } from '../REDUX/reducers/sectionsSlice';
import { remove_section } from '../REDUX/reducers/notesSlice';
import Bt from '../components/button';

import colors from '../colors.json';


const Container = styled.div`
	display: flex;
`
const BadgesContainer = styled.div`
	display: flex;
	margin-left: 8px;
`
const BadgeDelete = styled.button`
	position: absolute;
	top: 0;
	right: -36px;
	height: 100%;
	border-radius: 12px;
	border: 0;
	background-color: ${colors[1]};
	color: #FFF;
	cursor: pointer;
	font-size: 12px;
	transition: .6s right ease;
`
const BadgeContainer = styled.span`
	position: relative;
	background: #707070;
    color: #FFF;
    padding: 0 8px;
    border-radius: 12px;
    margin-right: 4px;
    overflow: hidden;
    &:hover ${BadgeDelete}{
    	right: 0;
    }
`
const BadgeTitle = styled.span`
`

const InputContainer = styled.form`
	margin-top: 4px;
	display: flex;
`
const BadgeInput = styled.input`
	border: 1px solid #FFF;
	border-radius: 4px;
`
const Button = styled.button`
	border: 1px solid #333;
	background: #333;
	color: #DDD;
	border-radius: 4px;
	text-transform: uppercase;
	margin-left: 4px;
	&:hover{
		background: #444;
		outline: 0;
	}
`

const Form = styled.form`
	/*border: 1px solid red;*/
	display: flex;
	padding: 8px;
`
const Input = styled.input`
	padding: 2px 4px;
	border: 0;
	background-color: #FFF;
`
const BackButton = styled(Link)`
	background: ${({colors}) => colors[0]};
  	color: #FFF;
  	font-size: 24px;
  	border: 0;
  	width: 34px;
  	height: 100%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	text-transform: uppercase;
	margin-left: 8px;
	cursor: pointer;
	&:hover{
		background: ${({colors}) => colors[1]};
		outline: 0;
	}
`

function Badge({title, onDelete, id}){
	return(
		<BadgeContainer>
			<BadgeTitle>{title}</BadgeTitle>
			<BadgeDelete type="button" onClick={() => onDelete(id)}>
				<Delete size={18} />
			</BadgeDelete>
		</BadgeContainer>
	)
}

function ProjectInput({id, badges, remove_section, add_to_path}){
	const location = useLocation()
	const [,path] = location.pathname.split('/')

	const isMobile = useMediaQuery({maxWidth: 767})

	const onDeleteBadge = (section) => {
		remove_section({id, section})
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const title = e.target[0].value
		if(title.length > 0){
			add_to_path({title, id})
			e.target[0].value = ''
		}
	}
	
	const badgesArr = badges.map(({id, title}) => <Badge key={id} id={id} title={title} onDelete={onDeleteBadge}/>)
	return (
		<Container>
			{isMobile && <BackButton 
				to={`/${path}`}
				colors={['#333', '#707070']}
			>
				<ChevronLeft/>
			</BackButton>}
			<Form onSubmit={handleSubmit}>
				<Input type="text" placeholder="Projects"/>
				<BadgesContainer>{badgesArr}</BadgesContainer>
			</Form>
		</Container>
	)
	
}

const mapState = (state, {id}) => {
	const ids = getNoteSectionsIds(state, id)
	return {badges: getNoteSections(state, ids)}
}

export default connect(mapState, { remove_section, add_to_path })(ProjectInput)