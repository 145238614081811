import { createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid';
import { new_note, select_note, remove_section } from './notesSlice';

const initialSection = {
	id: nanoid(),
	title: 'Notes',
	created_at: Date.now(),
	modified_at: Date.now(),
	notes: [],
	selectedNote: ''
}

const sectionsSlice = createSlice({
	name: 'sections',
	initialState: {
		selected: initialSection.id,
		byId: {[initialSection.id]: initialSection},
		allIds: [initialSection.id],
		byTitle: {[initialSection.title]: initialSection.id}
	},
	reducers: {
		select_section: (state, action) => {
			state.selected = action.payload
		},
		new_section_from_title: (state, action) => {
			if(!state.byTitle[action.payload]){
				const section = {
					id: nanoid(),
					title: action.payload,
					created_at: Date.now(),
					modified_at: Date.now(),
					notes: [],
					selectedNote: ''
				}
				state.byId[section.id] = section
				state.allIds.push(section.id)
				state.byTitle[section.title] = section.id
			}
		},
		add_note_to_section: (state, action) => {
			if(state.byId[action.payload.section].notes.indexOf(action.payload.note) === -1){
				state.byId[action.payload.section]['notes'].push(action.payload.note)
				state.byId[action.payload.section]['selectedNote'] = action.payload.note
			}
		},
		add_to_path: (state, action) => {}
	},
	extraReducers: {
		[new_note]:(state, action) => {
			state.byId[state.selected]['notes'].push(action.payload.id)
			state.byId[state.selected]['selectedNote'] = action.payload.id
		},
		[select_note]: (state, action) => {
			state.byId[state.selected]['selectedNote'] = action.payload
		},
		[remove_section]: (state, action) => {
			const index = state.byId[action.payload.section].notes.indexOf(action.payload.id)
			if(index >= 0){
				state.byId[action.payload.section].notes.splice(index, 1)
				if(state.byId[action.payload.section].selectedNote === action.payload.id){
					state.byId[action.payload.section].selectedNote = state.byId[action.payload.section].notes[0] || ''
				}
			}
			console.log(state.byId[action.payload.section].notes)
		}
	}
})

export const { select_section, add_to_path, new_section_from_title, add_note_to_section } = sectionsSlice.actions

export default sectionsSlice.reducer