import { combineReducers } from '@reduxjs/toolkit';
import draftToHtml from 'draftjs-to-html';

import sectionsReducer from './sectionsSlice';
import notesReducer from './notesSlice';

import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

function getIter(obj, cb){
	let ret = {}
	for(const [key, values] of Object.entries(obj)){
		ret[key] = cb(values)
	}
	return ret
}

function transformEntity(entity, text){
	if(entity.type === 'TITLE')
		return `<h1>${text}</h1>`
	return `${text}`
}

const migrations = {
	0: (state) => {
		return {
			...state,
			notes: {
				...state.notes,
				byId:{
					...state.notes.byId,
					...getIter(state.notes.byId, values => ({...values, state: null}))
				}
			}
		}
	},
	1: (state) => {
		return {
			...state,
			notes: {
				...state.notes,
				byId:{
					...state.notes.byId,
					...getIter(state.notes.byId, values => ({...values, state: draftToHtml(values.state, null, null, transformEntity)}))
				}
			}
		}
	}
}

const persistConfig = {
  key: 'notera-root',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, {debug: true})
}

export const rootReducer = combineReducers({
	sections: sectionsReducer,
	notes: notesReducer
})

export default persistReducer(persistConfig, rootReducer)