import { createSelector } from '@reduxjs/toolkit';

const selectAllNotes = state => state.notes.byId
const selectAllNotesId = state => state.notes.allIds
const selectSelectedNote = state => state.notes.selected


export const getAllNotes = createSelector(
	[selectAllNotes],
	(notes) => notes
)

export const getNotesId = createSelector(
	[selectAllNotesId],
	(notes) => notes
)

// export const getSelectedNoteId = createSelector(
// 	[selectSelectedNote],
// 	(note) => note
// )

export const getNoteById = createSelector(
	[getAllNotes, (state, id) => id],
	(notes, id) => notes[id] 
)

// export const getIsNoteSelected = createSelector(
// 	[getSelectedNoteId, (state, id) => id],
// 	(note, id) => note === id
// )
// 
// export const getSelectedNote = createSelector(
// 	[getSelectedNoteId, getAllNotes],
// 	(noteid, notes) => {
// 		console.log(notes, noteid)
// 		return notes[noteid]
// 	}
// )

export const getNoteSectionsIds = createSelector(
	[getNoteById],
	(note) => note.sections
)