import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { LogIn, LogOut } from 'react-feather';
import PropType from 'prop-types';

import { getSectionsId } from '../REDUX/selectors';

import Section from './section';
import AuthScreen from '../authScreen/authScreen';

import { signInWithGoogle, signOut } from '../SERVICES/firebase';
import { UserContext } from '../CONTEXT/userProvider';

import colors from '../colors.json';


const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`

const LogoContainer = styled.div`
	margin: 0;
	margin-bottom: 12px;
	height: 40px;
	text-align: center;
	color: #2196F3;
	display: flex;
	/*justify-content: center;*/
	align-items: center;
	/*padding: 12px 0;*/
`
const Holder = styled.div`
	/*border: 1px solid red;*/
	width: 48px;
`
const Logo = styled.div`
	/*border: 1px solid red;*/
	font-size: 32px;
	font-weight: bold;
	margin: 0 auto;
`
const AuthContainer = styled.div`
	/*border: 1px solid red;*/
	width: 48px;
	display: flex;
	flex-direction: column;
`
const Button = styled.button`
	border: 0;
	background-color: #FFF;
	cursor: pointer;
	color: #333;
	&:hover{
		color: ${colors[0]};
	}
`
const LogInContainer = styled(Button)`
	
`

const UserInfo = styled.div`
	/*border: 1px solid red;*/
	border-top: 1px solid #333;
	display: flex;
	align-items: center;
	padding: 4px;
	margin-top: auto;
`
const UserName = styled.span``
const UserImage = styled.span`
	/*border: 1px solid red;*/
	display: block;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 4px;
	& img{
		width: 100%;
		height: 100%;
	}
`
const LogOutContainer = styled(Button)`
	margin-left: auto;
`


export function NoteBook({sections}){
	const user = useContext(UserContext)
	const { displayName, photoURL } = user || {displayName: null, photoURL: null}

	const sectionArr = sections.map(section => 
		<Section 
			key={section} 
			id={section}
		/>
	)
	console.log(user)
	return (
		<Container>
			<LogoContainer>
				<AuthContainer>
					{user === null && <LogInContainer onClick={signInWithGoogle}>
						<LogIn/>
					</LogInContainer>}
				</AuthContainer>
				<Logo>Notera</Logo>
				<Holder/>
			</LogoContainer>
			<div>
				{sectionArr}
			</div>
			<UserInfo>
				{user !== null && 
					<>
						<UserImage>
							{photoURL !== undefined && <img src={photoURL} alt={displayName}/>}
						</UserImage>
						<UserName>
							{displayName}
						</UserName>
						<LogOutContainer onClick={signOut}><LogOut/></LogOutContainer>
					</>
				}
			</UserInfo>
		</Container>
	)
}

const mapState = state => ({
	sections: getSectionsId(state)
})

export default connect(mapState)(NoteBook)