import React from 'react';
import styled from 'styled-components';
import { LogIn } from 'react-feather';

import { signInWithGoogle } from '../SERVICES/firebase';
import colors from '../colors.json';

const Button = styled.button`
	border: 0;
	background-color: #FFF;
	cursor: pointer;
	color: #333;
	&:hover{
		color: ${colors[0]};
	}
`
export default function AuthScreen(){
	const hancleClick = () => {
		try{
			signInWithGoogle()
		}
		catch(err){
			console.log(err)
		}
	}

	return(
		<Button onClick={hancleClick}>
			<LogIn/>
		</Button>
	)
}