import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };



firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth()
const googleProvider = new firebase.auth.GoogleAuthProvider()

export const signInWithGoogle = () => {
	try{
		auth.signInWithPopup(googleProvider)
	}
	catch(err){
		console.log(err)
	}

}

export const signOut = () => {
    auth.signOut()
}