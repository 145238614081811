import React from 'react';
import styled, { css } from 'styled-components';
import { Tag as Title, Square } from 'react-feather';
import { useMediaQuery } from 'react-responsive';

import colors from '../colors';

const Container = styled.div`
	border: 1px solid red;
	${({isMobile}) => (
		isMobile && css`
			display: flex;
			justify-content: space-between;
		`
	)}
	&.ql-toolbar.ql-snow .ql-formats{
		${({isMobile}) => (
		isMobile && css`
			display: flex;
			flex-direction: column;
			margin: 0;
		`
		)}
	}
`
const ToolBarButton = styled.button``

const ToolBarSeparator = styled.span`
	border: 1px solid #707070;
`

export default function ToolBar(){
	const bgColors = [...colors]
  	bgColors.push('#FFF')
	const isMobile = useMediaQuery({maxWidth: 767})

	return (
		<Container isMobile={isMobile}>
			<ToolBarSeparator className="ql-formats">
				<ToolBarButton className="ql-title">
					<Title size={18}/>
				</ToolBarButton>
				<ToolBarButton className="ql-list" value="unchecked">
					<Square size={18}/>
				</ToolBarButton>
			</ToolBarSeparator>
			<ToolBarSeparator className="ql-formats">
				<select className="ql-header">
					<option value="">No</option>
					<option value="1">H1</option>
					<option value="2">H2</option>
					<option value="3">H3</option>
					<option value="4">H4</option>
				</select>
				<select className="ql-color"/>
			</ToolBarSeparator>
			<ToolBarSeparator className="ql-formats">
				<ToolBarButton className="ql-bold">Bold</ToolBarButton>
				<ToolBarButton className="ql-italic"/>
			</ToolBarSeparator>
			<ToolBarSeparator className="ql-formats">
				<ToolBarButton className="ql-list" value="ordered"/>
				<ToolBarButton className="ql-list" value="bullet"/>
			</ToolBarSeparator>
			<ToolBarSeparator className="ql-formats">
				<ToolBarButton className="ql-indent" value="-1"/>
				<ToolBarButton className="ql-indent" value="+1"/>
			</ToolBarSeparator>
			<ToolBarSeparator className="ql-formats">
				<ToolBarButton className="ql-image"/>
				<ToolBarButton className="ql-link"/>
			</ToolBarSeparator>
			<ToolBarSeparator className="ql-formats">
				<ToolBarButton className="ql-clean"/>
				<select className="ql-background">
					{bgColors.map(c => <option key={c} value={c}/>)}
				</select>
			</ToolBarSeparator>
		</Container>
	)
}

ToolBar.toString = Container.toString