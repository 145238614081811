import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import UserProvider from './CONTEXT/userProvider';
import { persistor, store } from './REDUX/store';

import Main from './main';

function App(){
	return (
		<UserProvider>
			<BrowserRouter>
			    <Provider store={store}>
			    	<PersistGate loading={null} persistor={persistor}>
						<Main/>
					</PersistGate>
			    </Provider>
		    </BrowserRouter>
	    </UserProvider>
	)
}

export default App;
