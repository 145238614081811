import React, { createContext, Component, useState, useEffect } from 'react';
import { auth } from '../SERVICES/firebase';


export const UserContext = createContext({user: null})

class UserProvider extends Component{
	constructor(){
		super();
		this.state = {
			user: null
		}
	}

	componentDidMount(){
		auth.onAuthStateChanged( u => {
			this.setState({user: u})
		})
	}

	render() {
		return(
			<UserContext.Provider value={this.state.user}>
				{this.props.children}
			</UserContext.Provider>
		)
	}
}

export default UserProvider;