import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';

import ReactQuill from 'react-quill';
import Delta from 'quill-delta';
import 'react-quill/dist/quill.snow.css';

import { getNoteById } from '../REDUX/selectors';
import { edit_note } from '../REDUX/reducers/notesSlice';
import { add_to_path } from '../REDUX/reducers/sectionsSlice';

import ProjectInput from './projectInput';
import ToolBar from './toolbar';

import './styles/quill-fonts.css';
import './formats/titleFormat';



const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #F3F8FB;
`
const EditorContainer = styled.div`
	font-family: 'Roboto';
	font-size: 16px;
	margin: 16px 12px;
	background-color: ${({color}) => color ? color : '#FFF'};
	border-radius: 12px;
	overflow-y: auto;
`
const QuillEditor = styled(ReactQuill)`
	display: flex;
	flex-direction: column;
	height: calc(100% - 44px - 40px);
	${({isMobile}) => (
		isMobile && css`
			height: calc(100% - 88px - 40px);
		`
	)}
`


function NoteEditor({note={}, edit_note, add_to_path}){

	const editor = typeof note.state === 'string' ? note.state : new Delta(note.state || null)
	
	const [noteTitle, setNoteTitle] = useState(note.title)
	const [noteColor, setNoteColor] = useState(note.color)

	const isMobile = useMediaQuery({maxWidth: 767})

	const mod = useRef(false)
	const editorRef = useRef(null)

	useEffect(() => {
		const timer = setInterval(() => {
			if(mod.current && editorRef.current){
				edit_note({id: note.id, state: {ops: editorRef.current.value.ops}})
				mod.current = false
			}
		}, 2000)

		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		edit_note({id: note.id, title: noteTitle, color: noteColor})
	}, [noteTitle, noteColor])

	useEffect(() => {
		if(editorRef){
			editorRef.current.focus()
		}
	}, [editorRef])
	
	const handleChange = () => {
		mod.current = true
	}

	const modules = { 
		toolbar: {
			container: ToolBar.toString(),
			handlers: {
				title: function(value){ //TODO: Fix double call

					console.log(value)
					this.quill.format('title', value)
					if(value){
						const selection = this.quill.getSelection()
						const line = this.quill.getLine(selection ? selection.index : 0)

						const title = line[0].children.head.text
						if(title && typeof title === 'string' && title.length > 0){
							setNoteTitle(title)
							mod.current = true
						}
					}
					
				},
				background: function(value){
					setNoteColor(value)
					mod.current = true
					editorRef.current.focus()
				}
			}
		}
	}

  	const formats = [
  		'title','font',
	    'header',
	    'bold', 'italic', 'underline', 'strike', 'blockquote',
	    'list', 'bullet', 'indent',
	    'link', 'image', 'color', 'size'
  	]

  	
  	if(!note.id) return <Container/>
  	
	return (
		<Container>
			<ProjectInput id={note.id}/>
			{!isMobile && <ToolBar/>}
			<QuillEditor
				ref={editorRef}
				id="quill-editor"
				theme="snow" 
				modules={modules}
				formats={formats}
				defaultValue={editor}
				onChange={handleChange}
				isMobile={isMobile}
			>
				<EditorContainer color={noteColor}/>
			</QuillEditor>
			{isMobile && <ToolBar/>}
		</Container>
	)
}

const noteEqual = (prev, next) => {
	if(!prev.note) return false
	return prev.note.id === next.note.id
}

const mapState = (state, {id}) => ({
	note: getNoteById(state, id)
})

export default connect(mapState, { edit_note, add_to_path })(React.memo(NoteEditor, noteEqual))