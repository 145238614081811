import React, { useContext } from 'react';
import { Link, useLocation, generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getNoteById, getIsNoteSelected } from '../REDUX/selectors';
import { select_note } from '../REDUX/reducers/notesSlice';

import { PageContext } from '../CONTEXT/pages-navigation';

const Container = styled(Link)`
	margin-bottom: 8px;
	cursor: pointer;
	color: #333;
	text-decoration: none;
`
const SelectContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 4px;
	border-left: 8px solid ${({selected, color}) => selected ? color : 'transparent'};
`

function Note({note, selected, select_note}){
	const {pathname} = useLocation()
	const [,section] = pathname.split('/')

	const { id, color, title, datetime } = note
	const path = generatePath("/:section/:note", {section, note: id})

	const t = title || new Date(datetime).toLocaleDateString()
	const { setPage } = useContext(PageContext)

	const handleClick = () => {
		select_note(id)
		setPage('editor')
	}

	return (
		<Container to={path} color={color} onClick={handleClick}>
			<SelectContainer selected={selected} color={color}>
				{t}
			</SelectContainer>
		</Container>
	)
}

const mapState = (state, {id}) => ({
	note: getNoteById(state, id)
})
export default connect(mapState, { select_note })(Note)